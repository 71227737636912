import { Record } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { obj_type, array_type, record_type, float64_type, string_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";
import { unwrap, map, defaultArg } from "../fable_modules/fable-library-js.4.17.0/Option.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library-js.4.17.0/Seq.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ResponsiveContainer, ComposedChart, Legend, LineChart, ReferenceLine, Line, Tooltip, YAxis, XAxis } from "recharts";
import { quoteToString, utcDateToLocalDate } from "../Utils.js";
import { cons, empty as empty_1, ofArray } from "../fable_modules/fable-library-js.4.17.0/List.js";
import { int32ToString, uncurry2, createObj } from "../fable_modules/fable-library-js.4.17.0/Util.js";
import { format } from "../fable_modules/fable-library-js.4.17.0/String.js";
import { Interop_reactApi as Interop_reactApi_1 } from "../fable_modules/Feliz.2.7.0/Interop.fs.js";
import { createElement } from "react";
import React from "react";
import { useReact_useEffectOnce_3A5B6456, useFeliz_React__React_useState_Static_1505 } from "../fable_modules/Feliz.2.7.0/React.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "../fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "../fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { FetchError } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.17.0/Result.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "../fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "../fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { keyValueList } from "../fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6 } from "../fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { DataServerBaseUrl } from "../Session.js";
import { fromString } from "../fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { LoadingIndicator_loadingIndicator, Alert_snackError } from "../ViewHelpers.js";
import { MuiHelpers_createElement } from "../Feliz.MaterialUI/Mui.js";
import { Checkbox, FormControlLabel } from "@mui/material";
import { tryLast } from "../fable_modules/fable-library-js.4.17.0/Array.js";
import { DefaultBorder, Dimensions_TextSmall } from "../Design.js";
import { StockRating__ToTextColorCode, StockRating, StockRating__ToColorCode } from "../Shared/Shared.js";
import { AssetBanner } from "../Content/Ads.js";

class AssetRSI extends Record {
    constructor(Date$, RSI, Close) {
        super();
        this.Date = Date$;
        this.RSI = RSI;
        this.Close = Close;
    }
}

function AssetRSI_$reflection() {
    return record_type("Investfora.AssetTA.AssetRSI", [], AssetRSI, () => [["Date", string_type], ["RSI", float64_type], ["Close", float64_type]]);
}

function rsiChart(data, showReferenceLines, showClose) {
    let properties_16, elements_1, properties_9, elements;
    const properties_17 = ofArray([["minWidth", 250], ["minHeight", 210], ["children", !showClose ? ((properties_16 = ofArray([["width", 450], ["height", 300], ["data", data], ["margin", {
        top: defaultArg(0, 0),
        right: defaultArg(0, 0),
        left: defaultArg(0, 0),
        bottom: defaultArg(0, 0),
    }], (elements_1 = toList(delay(() => append(singleton(Interop_reactApi.createElement(XAxis, {
        dataKey: "Date",
        tickFormatter: (tick_3) => (`${utcDateToLocalDate(tick_3)}`),
    })), delay(() => {
        let properties_11;
        return append(singleton((properties_11 = ofArray([["orientation", "right"], ["tickFormatter", (tick_4) => (`${~~tick_4}`)], ["domain", [0, 100]]]), Interop_reactApi.createElement(YAxis, createObj(properties_11)))), delay(() => append(singleton(Interop_reactApi.createElement(Tooltip, {
            formatter: (delegateArg_3, delegateArg_1_1, delegateArg_2_1) => format('{0:' + "0.00" + '}', delegateArg_3),
        })), delay(() => append(singleton(Interop_reactApi.createElement(Line, {
            dataKey: "RSI",
            stroke: "#5F5566",
            strokeWidth: 3,
            dot: false,
        })), delay(() => (showReferenceLines ? append(singleton(Interop_reactApi.createElement(ReferenceLine, {
            y: 70,
            label: "Overkjøpt",
            strokeWidth: 2,
        })), delay(() => singleton(Interop_reactApi.createElement(ReferenceLine, {
            y: 30,
            label: "Oversolgt",
            strokeWidth: 2,
        })))) : empty())))))));
    })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements_1))])]), Interop_reactApi.createElement(LineChart, createObj(properties_16)))) : ((properties_9 = ofArray([["width", 450], ["height", 300], ["data", data], ["margin", {
        top: defaultArg(0, 0),
        right: defaultArg(0, 0),
        left: defaultArg(0, 0),
        bottom: defaultArg(0, 0),
    }], (elements = toList(delay(() => append(singleton(Interop_reactApi.createElement(XAxis, {
        dataKey: "Date",
        tickFormatter: (tick) => (`${utcDateToLocalDate(tick)}`),
    })), delay(() => {
        let properties_1;
        return append(singleton((properties_1 = ofArray([["yAxisId", "left"], ["tickFormatter", (tick_1) => {
            if (tick_1 >= 1000000000) {
                const bn = tick_1 / 1000000000;
                return `${format('{0:' + "0.0" + '}', bn)} mrd`;
            }
            else if (tick_1 >= 1000000) {
                const mill = tick_1 / 1000000;
                return `${format('{0:' + "0.0" + '}', mill)}m`;
            }
            else if (tick_1 >= 1000) {
                const k = tick_1 / 1000;
                return `${format('{0:' + "0.0" + '}', k)}k`;
            }
            else {
                return `${tick_1}`;
            }
        }], ["domain", ["auto", "auto"]]]), Interop_reactApi.createElement(YAxis, createObj(properties_1)))), delay(() => {
            let properties_2;
            return append(singleton((properties_2 = ofArray([["yAxisId", "right"], ["orientation", "right"], ["tickFormatter", (tick_2) => (`${~~tick_2}`)], ["domain", ["auto", "auto"]]]), Interop_reactApi.createElement(YAxis, createObj(properties_2)))), delay(() => append(singleton(Interop_reactApi.createElement(Tooltip, {
                formatter: (delegateArg, delegateArg_1, delegateArg_2) => format('{0:' + "0.00" + '}', delegateArg),
            })), delay(() => append(showReferenceLines ? append(singleton(Interop_reactApi.createElement(ReferenceLine, {
                y: 70,
                label: "Overkjøpt",
                yAxisId: "right",
                strokeWidth: 2,
            })), delay(() => singleton(Interop_reactApi.createElement(ReferenceLine, {
                y: 30,
                label: "Oversolgt",
                yAxisId: "right",
                strokeWidth: 2,
            })))) : empty(), delay(() => append(singleton(Interop_reactApi.createElement(Legend, {})), delay(() => append(singleton(Interop_reactApi.createElement(Line, {
                dataKey: "Close",
                stroke: "var(--text-dim)",
                strokeWidth: 3,
                dot: false,
                yAxisId: "left",
            })), delay(() => singleton(Interop_reactApi.createElement(Line, {
                dataKey: "RSI",
                stroke: "#5F5566",
                strokeWidth: 3,
                dot: false,
                yAxisId: "right",
            }))))))))))));
        }));
    })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))])]), Interop_reactApi.createElement(ComposedChart, createObj(properties_9))))]]);
    return Interop_reactApi.createElement(ResponsiveContainer, createObj(properties_17));
}

export function RelativeStrengthIndex(relativeStrengthIndexInputProps) {
    let elems_7, elems_6;
    const ticker = relativeStrengthIndexInputProps.ticker;
    const market = relativeStrengthIndexInputProps.market;
    const patternInput = useFeliz_React__React_useState_Static_1505(undefined);
    const rsi = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    const setErrMsg = patternInput_1[1];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(true);
    const showReferenceLines = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(false);
    const showClose = patternInput_3[0];
    useReact_useEffectOnce_3A5B6456(() => {
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder, data_1, caseStrategy_2, extra_2;
            return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(AssetRSI_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(empty_1(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty_1()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/ta/rsi/${market}/${ticker}`, properties_3).then((_arg_1) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg_1, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(AssetRSI_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_2) => {
                            let matchValue;
                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_2), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })()))))).then((_arg_3) => {
                const res = _arg_3;
                if (res.tag === 1) {
                    setErrMsg("Kunne ikke laste teknisk analyse for aksje. Vennligst prøv igjen senere.");
                    return Promise.resolve();
                }
                else {
                    patternInput[1](res.fields[0]);
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
    });
    return createElement("div", createObj(ofArray([["style", {
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        border: (((1 + "px ") + "solid") + " ") + "#e1e1e1",
        padding: 2 + "vw",
    }], (elems_7 = [Alert_snackError(patternInput_1[0], () => {
        setErrMsg(undefined);
    }), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        gap: 10 + "px ",
    }], (elems_6 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        }], (elems = [createElement("h2", {
            style: {
                fontSize: 2 + "rem",
                fontWeight: 500,
                margin: 0,
            },
            children: "Relative Strength Index (RSI)",
        })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])))), delay(() => {
            let rsi_1;
            return append(singleton(createElement("p", {
                style: {
                    margin: 0,
                },
                children: `RSI (14) utvikling over siste ${(rsi != null) ? ((rsi_1 = rsi, int32ToString(rsi_1.length))) : "90"} handelsdager for aksjen. Tradisjonelt vurderer man en RSI verdi over 70 som overkjøpt og verdi under 30 som oversolgt ifølge teknisk analyse. Utregningen er gjort med J. Welles Wilder's originale formell, ofte kalt "Wilder's Smoothing Method".`,
            })), delay(() => {
                const matchValue_1 = rsi;
                if (matchValue_1 != null) {
                    const x_2 = matchValue_1;
                    return append(singleton(rsiChart(x_2, showReferenceLines, showClose)), delay(() => {
                        let elems_1;
                        return append(singleton(createElement("div", createObj(ofArray([["style", {
                            display: "flex",
                            alignItems: "center",
                        }], (elems_1 = [MuiHelpers_createElement(FormControlLabel, [["label", "Vis referanselinjer"], ["checked", showReferenceLines], ["onChange", (e) => {
                            patternInput_2[1](e.target.checked);
                        }], ["control", MuiHelpers_createElement(Checkbox, [["size", "small"]])]]), MuiHelpers_createElement(FormControlLabel, [["label", "Vis sluttkurs"], ["checked", showClose], ["onChange", (e_1) => {
                            patternInput_3[1](e_1.target.checked);
                        }], ["control", MuiHelpers_createElement(Checkbox, [["size", "small"]])]])], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])))), delay(() => {
                            let elems_5, elems_3, elems_4, l_1, l_2, l_3;
                            const latest = tryLast(x_2);
                            return append(singleton(createElement("div", createObj(ofArray([["style", {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }], (elems_5 = [createElement("div", createObj(ofArray([["style", {
                                display: "flex",
                                flexDirection: "column",
                            }], (elems_3 = toList(delay(() => {
                                let elems_2;
                                if (latest != null) {
                                    const l = latest;
                                    return append(singleton(createElement("span", createObj(ofArray([["style", {}], (elems_2 = [createElement("span", {
                                        children: ["RSI: "],
                                    }), createElement("span", {
                                        style: {
                                            fontWeight: "bold",
                                        },
                                        children: format('{0:' + "0.00" + '}', l.RSI),
                                    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])))), delay(() => singleton(createElement("span", {
                                        style: {
                                            color: "var(--text-dim)",
                                            fontSize: Dimensions_TextSmall,
                                        },
                                        children: `Per ${utcDateToLocalDate(l.Date)}`,
                                    }))));
                                }
                                else {
                                    return singleton(createElement("span", {
                                        children: ["RSI: N/A"],
                                    }));
                                }
                            })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["style", {}], (elems_4 = [createElement("span", {
                                children: ["Vurdering: "],
                            }), createElement("span", {
                                style: {
                                    borderRadius: 5,
                                    padding: 5,
                                    backgroundColor: (latest != null) ? ((l_1 = latest, (l_1.RSI >= 70) ? "var(--sell)" : ((l_1.RSI <= 30) ? "var(--buy)" : StockRating__ToColorCode(new StockRating(2, []))))) : "var(--bg-main)",
                                    color: (latest != null) ? ((l_2 = latest, ((l_2.RSI > 30) && (l_2.RSI < 70)) ? StockRating__ToTextColorCode(new StockRating(2, [])) : "#fff")) : "var(--text-main)",
                                },
                                children: (latest != null) ? ((l_3 = latest, (l_3.RSI >= 70) ? "Overkjøpt" : ((l_3.RSI <= 30) ? "Oversolgt" : "Nøytral"))) : "N/A",
                            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                                let l_4;
                                return singleton(createElement("span", {
                                    children: (latest != null) ? ((l_4 = latest, (l_4.RSI >= 70) ? "RSI over 70 indikerer at aksjen er overkjøpt" : ((l_4.RSI <= 30) ? "RSI under 30 indikerer at aksjen er oversolgt." : ((l_4.RSI > 60) ? (`RSI på ${quoteToString(l_4.RSI)} indikerer at aksjen nærmer seg overkjøpt.`) : ((l_4.RSI < 40) ? (`RSI på ${quoteToString(l_4.RSI)} indikerer at aksjen nærmer seg oversolgt.`) : (`RSI på ${quoteToString(l_4.RSI)} gir ingen klar indikasjon på om aksjen er overkjøpt eller oversolgt.`)))))) : "Ikke nok data",
                                }));
                            }));
                        }));
                    }));
                }
                else {
                    return singleton(LoadingIndicator_loadingIndicator());
                }
            }));
        }));
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_6))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_7))])])));
}

function drawAppInfo() {
    let elems_3, elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["className", "default-gap"], ["style", createObj(ofArray([["display", "flex"], ["flexDirection", "column"], ["justifyContent", "space-around"], ["alignItems", "center"], ["backgroundColor", "#FFFFFF"], ["borderRadius", 5], DefaultBorder, ["padding", 1 + "vw"], ["textAlign", "left"]]))], (elems_3 = [createElement("span", {
        children: ["Få push-varslinger ved endringer i RSI-verdi. Last ned aksje.io appen!"],
    }), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        alignItems: "center",
        gap: 20 + "px ",
    }], (elems_2 = [createElement("a", createObj(ofArray([["style", {
        lineHeight: 0 + "px",
    }], ["href", "https://apps.apple.com/app/aksje-io/id6448765436"], ["aria-label", "Last ned aksje.io fra Apple App Store"], (elems = [createElement("img", {
        src: "/images/app_store_download.svg",
        target: "_blank",
        alt: "Lenke til aksje.io på Apple App Store",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("a", createObj(ofArray([["style", {
        lineHeight: 0 + "px",
    }], ["href", "https://play.google.com/store/apps/details?id=io.aksje.app&pli=1"], ["target", "_blank"], (elems_1 = [createElement("img", {
        style: {
            height: 50,
        },
        src: "https://play.google.com/intl/en_us/badges/static/images/badges/no_badge_web_generic.png",
        alt: "Last ned fra Google Play Store",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])));
}

export function TechnicalAnalysis(technicalAnalysisInputProps) {
    let elems;
    const ticker = technicalAnalysisInputProps.ticker;
    const market = technicalAnalysisInputProps.market;
    return createElement("div", createObj(ofArray([["className", "default-gap"], ["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems = [createElement("div", {
        key: "rsi",
        children: createElement(RelativeStrengthIndex, {
            market: market,
            ticker: ticker,
        }),
    }), createElement(AssetBanner, null), drawAppInfo()], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])));
}

